// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import '../stylesheets/active_admin';

import '@activeadmin/activeadmin';
import 'activeadmin_addons';
import 'activeadmin_quill_editor';
import 'chartkick/chart.js';

const hideDependanceDirecte = () => {
  const typeOrganisation = jQuery('#entite_type_organisation_id');
  const dependanceDirecte = jQuery('#entite_dependance_directe_input');
  typeOrganisation.on('change', (event) => {
    const typeOrganisationValue = event.target.selectedOptions[0].outerText;

    if (typeOrganisationValue === 'Laboratoire') {
      dependanceDirecte.show();
    } else {
      dependanceDirecte.hide();
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  hideDependanceDirecte();

  document
    .querySelector('#impersonate-user')
    .addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      $.post(event.target.href, (data) => {
        const frontWindow = window.open(
          'https://datakairos.unif.fr/login',
        );
        setTimeout(() => {
          frontWindow.postMessage(
            data,
            'https://datakairos.unif.fr',
          );
        }, 2000);
      });
    });
});
